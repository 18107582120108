import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import StaticPageHeader from '../components/staticPageHeader';
import Footer from '../components/footer';
import CookiePopup from '../components/cookiePopup';
import './layout.css';
import ContentBlock from './contentBlock';
import breakpoint from 'styled-components-breakpoint';

const theme = {
  primary: '#1111F5',
  grey: '#F6F6F6',
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const PageTitle = styled.h1`
  font-size: 60px;
  color: ${props => props.theme.primary};
  margin-bottom: 75px;
  margin-top: 40px;
  ${breakpoint('lg')`
    font-size: 70px;
  `}
`;

const Layout = ({ children, title }) => {
  return (
    <ThemeProvider theme={theme}>
      <StaticPageHeader />
      <div>
        <main>
          <ContentBlock>
            <PageTitle>{title}</PageTitle>
            {children}
          </ContentBlock>

          <Footer />
        </main>
      </div>
      <CookiePopup />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
