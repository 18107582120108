import React from 'react';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import StaticPageLayout from '../components/staticPageLayout';
import SEO from '../components/seo';
import styled from 'styled-components';

const PolicyPageContent = styled.div`
  max-width: 780px;
  h2 {
    font-size: 18px;
    margin-top: 70px;
  }
  li,
  p {
    margin-bottom: 0;
  }
  ol {
    list-style-type: none;
    margin: 0;
  }
`;

const PolicyPage = () => {
  const { t } = useTranslation();
  return (
    <StaticPageLayout title={t('policy')}>
      <SEO title={t('policy').replace('p', 'P')} />
      <PolicyPageContent>
        <h2>§.1 Postanowienia Ogólne</h2>
        <ol>
          <li>
            Administratorem danych jest studio FNC Tomasz Tobys z siedzibą w Krakowie, na placu Jana
            Matejki 12/422, NIP: 9372584947
          </li>
          <li>
            Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa
            (Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.
            w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
            sprawie swobodnego przepływu takich danych oraz krajowe regulacje w w/w zakresie, w tym
            zwłaszcza ustawa o ochronie danych osobowych).
          </li>
          <li>Dane przechowywane są na zabezpieczonych serwerach.</li>
          <li>
            Dane osobowe podawane w formularzach są traktowane jako poufne i nie są widoczne dla
            osób nieuprawnionych.
          </li>
        </ol>
        <h2>§2. Administrator Danych</h2>
        <ol>
          <li>
            Studio jest administratorem danych swoich klientów przekazanych przez formularz
            kontaktowy umieszczony na stronie. Przechowywane dane to: imię, nazwisko, adres e-mail.
          </li>
          <li>
            Dane osobowe przetwarzane są:
            <br />
            a. zgodnie z przepisami dotyczącymi ochrony danych osobowych,
            <br />
            b. zgodnie z Polityką Prywatności,
            <br />
            c. w zakresie i celu niezbędnym do działalności studia,
          </li>
          <li>
            Każda osoba, której dane dotyczą, ma prawo do: dostępu do danych, sprostowania,
            usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu oraz prawo wniesienia skargi
            do organu nadzorczego.
          </li>
          <li>
            Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia
            prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.
          </li>
          <li>
            Kontakt z osobą nadzorującą przetwarzanie danych osobowych jest możliwy drogą
            elektroniczną pod adresem e-mail: kontakt@studiofnc.pl
          </li>
          <li>
            Studio nie udostępniania danych osobowych innym podmiotom niż upoważnionym na podstawie
            właściwych przepisów prawa lub umów o powierzeniu przetwarzania danych osobowych.
          </li>
        </ol>
        <h2>§3. Pliki cookies</h2>
        <ol>
          <li>
            Witryna www.studiofnc.pl używa plików cookies. Są to pliki tekstowe wysyłane przez
            serwer www i przechowywane przez przeglądarki. Kiedy przeglądarka ponownie połączy się
            ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik.
            Parametry pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który
            je utworzył. Cookies ułatwiają korzystanie z wcześniej odwiedzonych witryn.
          </li>
          <li>
            Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka,
            rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie,
            lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularzy.
          </li>
          <li>
            Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób użytkownicy korzystają
            z witryny, aby usprawniać funkcjonowanie serwisu, zapewniając bardziej efektywną i
            bezproblemową nawigację. Monitorowanie informacji o użytkownikach prowadzone jest przy
            pomocy narzędzia Google Analitics, które rejestruje zachowanie użytkownika na stronie.
          </li>
          <li>
            Cookies identyfikuje użytkownika, co pozwala na dopasowanie treści witryny, z której
            korzysta, do jego potrzeb. Stosujemy pliki cookies, aby zagwarantować najwyższy standard
            wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz studio FNC w
            celu optymalizacji działań komunikacyjnych i marketingowych.
          </li>
          <li>
            Na naszej witrynie wykorzystujemy następujące pliki cookies:
            <br />
            a. umożliwiające korzystanie z usług dostępnych w ramach serwisu,
            <br />
            b. służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w
            zakresie uwierzytelniania w ramach serwisu;
            <br />
            c. umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych
            serwisu;
            <br />
            d. umożliwiające „zapamiętanie” wybranych przez użytkownika ustawień i personalizację
            interfejsu użytkownika
          </li>
          <li>
            Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji gromadzenia
            cookies poprzez zmianę ustawień w przeglądarce internetowej.
          </li>
          <li>
            Dodatkowe dane osobowe (takie jak adres e-mail) zbierane są jedynie w miejscach, w
            których użytkownik wyraźnie wyraził na to zgodę np. wypełniając formularz. Powyższe dane
            zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej funkcji.
          </li>
        </ol>
        <h2>§.4 Cel zbierania danych</h2>
        <ol>
          <li>
            Dane zbierane są tylko i wyłącznie w celu realizowania działalności studio FNC:
            sprzedaży usług i produktów.
          </li>
          <li>
            Cel zbierania danych ze względu na rodzaj:
            <br />
            a. Pliki cookie – ulepszanie i dostosowywanie serwisu pod potrzeby użytkowników,
            monitorowanie wyników wyszukiwania i optymalizacja działania nawigacji.
            <br />
            b. Formularz kontaktowy – służy do kontaktu między klientem a studiem, dane są tylko
            wykorzystywane do odpowiedzi na zadane pytanie.
          </li>
        </ol>
      </PolicyPageContent>
    </StaticPageLayout>
  );
};

export default PolicyPage;
