import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

import logo from '../images/logo_black.svg';
import breakpoint from 'styled-components-breakpoint';
import Content from './content';
import { ArrowLinkLeft } from './buttons';
import { usePageContext } from '@3nvi/gatsby-theme-intl';

const HeaderWrapper = styled.header``;

const Navbar = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back {
    color: transparent;
    text-indent: -100vw;
  }
  ${breakpoint('lg')`
    padding: 50px 0;
  }`}
  @media screen and (min-width: 1650px) {
    .back {
      height: 135px;
      position: absolute;
      top: 0;
      left: 50px;
    }
  }
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  img {
    height: 35px;
  }
  h1 {
    display: none;
  }
`;

const StaticPageHeader = ({ siteTitle }) => {
  const { lang } = usePageContext();
  return (
    <HeaderWrapper>
      <Content className="header-content">
        <Navbar>
          <Logo to={`/${lang}`}>
            <img src={logo} alt={siteTitle} />
            <h1>studio FNC</h1>
          </Logo>
          <ArrowLinkLeft textColor="black" href={`/${lang}`} className="back">
            powrót
          </ArrowLinkLeft>
        </Navbar>
      </Content>
    </HeaderWrapper>
  );
};

StaticPageHeader.propTypes = {
  siteTitle: PropTypes.string,
};

StaticPageHeader.defaultProps = {
  siteTitle: ``,
};

export default StaticPageHeader;
